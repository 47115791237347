export default class User implements IUser {
    createdAt: string;
    firstName: string;
    login: string;
    lastName: string;

    constructor(data: any) {
        this.createdAt = data.createdAt;
        this.login = data.login;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }

    getUserName(): string {
        return this.firstName + " " + this.lastName;
    }

}

export interface IUser {
    createdAt: string;
    login: string;
    firstName: string;
    lastName: string;
}