import React, { lazy, Suspense } from "react";
import * as FrontUrl from '../../constants/FrontendUrl';
import { APP_PREFIX_PATH } from '../../configs/AppConfig'
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from '../../components/shared-components/Loading';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={FrontUrl.ACTIVITY.SELF} component={lazy(() => import(`./activities`))} />
        <Route path={FrontUrl.DIFFICULTY.SELF} component={lazy(() => import(`./difficulties`))} />
        <Route path={FrontUrl.SOLUTION.SELF} component={lazy(() => import(`./solutions`))} />
        <Route path={FrontUrl.MODULE.SELF} component={lazy(() => import(`./modules`))} />
        <Route path={FrontUrl.EXPERTISE.SELF} component={lazy(() => import(`./expertises`))} />
        <Route path={FrontUrl.PARTICIPANT.SELF} component={lazy(() => import(`./participants`))} />
        <Route path={FrontUrl.FACILITATOR.SELF} component={lazy(() => import(`./facilitators`))} />
        <Route path={FrontUrl.CERTIFICATE.SELF} component={lazy(() => import(`./certificates`))} />
        <Route path={FrontUrl.RECOMMANDATION.SELF} component={lazy(() => import(`./recommandations`))} />
        <Route path={FrontUrl.SESSION.SELF} component={lazy(() => import(`./sessions`))} />
        <Route path={FrontUrl.REASON.SELF} component={lazy(() => import(`./reasons`))} />
        <Route path={FrontUrl.PARTICIPATION.SELF} component={lazy(() => import(`./participations`))} />
        <Route path={FrontUrl.USER.SELF} component={lazy(() => import(`./users`))} />
        <Route path={FrontUrl.EVALUATION.SELF} component={lazy(() => import(`./evaluations`))} />
        <Route path={FrontUrl.SKILL.SELF} component={lazy(() => import(`./skills`))} />
        <Route path={FrontUrl.STEP.SELF} component={lazy(() => import(`./step-evaluations`))} />
        <Route path={FrontUrl.SUCCESS.SELF} component={lazy(() => import(`./successes`))} />
        {/* <Route path={FrontUrl.LINK_SUCCESS.SELF} component={lazy(() => import(`./success-participants`))} /> */}
        {/* <Route path={FrontUrl.LINK_RECOMMANDATION.SELF} component={lazy(() => import(`./link-recommandations`))} /> */}
        <Route path={FrontUrl.LINK_DIFFICULTY.SELF} component={lazy(() => import(`./link-difficulties`))} />
        <Route path={FrontUrl.HOME} component={lazy(() => import(`./home`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={FrontUrl.HOME} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);