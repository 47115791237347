import { FlagOutlined, TeamOutlined, FileDoneOutlined, FileExclamationOutlined, IssuesCloseOutlined, UserAddOutlined, SnippetsOutlined, DashboardOutlined, LogoutOutlined, TagOutlined, CarryOutOutlined, FolderOpenOutlined, RiseOutlined, CalendarOutlined, AuditOutlined } from '@ant-design/icons';
import { ACTIVITY, CERTIFICATE, DIFFICULTY, EVALUATION, EXPERTISE, FACILITATOR, LINK_DIFFICULTY, MODULE, PARTICIPANT, PARTICIPATION, REASON, RECOMMANDATION, SESSION, SKILL, SOLUTION, STEP, SUCCESS, USER } from 'constants/FrontendUrl';

export const navTree = [{
  key: 'dashboards',
  title: 'Général',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      breadcrumb: false,
      title: 'Facilitateurs',
      key: 'facilitateurs',
      icon: UserAddOutlined,
      path: FACILITATOR.LIST,
    },
    {
      breadcrumb: false,
      title: 'Participants',
      key: 'participants',
      icon: TeamOutlined,
      path: PARTICIPANT.LIST,
    },
    {
      breadcrumb: false,
      title: 'Activités',
      key: 'activities',
      icon: SnippetsOutlined,
      path: ACTIVITY.LIST,
    },
    {
      breadcrumb: false,
      title: 'Modules',
      key: 'modules',
      icon: FolderOpenOutlined,
      path: MODULE.LIST,
    },
    {
      breadcrumb: false,
      title: 'Comptétences',
      key: 'expertises',
      icon: RiseOutlined,
      path: EXPERTISE.LIST,
    },
    {
      breadcrumb: false,
      title: 'Comptétences acquises',
      key: 'skills',
      icon: RiseOutlined,
      path: SKILL.LIST,
    },
    {
      breadcrumb: false,
      title: 'Programmations',
      key: 'sessions',
      icon: CalendarOutlined,
      path: SESSION.LIST,
    },
    {
      breadcrumb: false,
      title: 'Raisons',
      key: 'reasons',
      icon: TagOutlined,
      path: REASON.LIST,
    },
    {
      breadcrumb: false,
      title: 'Participations',
      key: 'participations',
      icon: CarryOutOutlined,
      path: PARTICIPATION.LIST,
    },
    {
      breadcrumb: false,
      title: 'Réussites',
      key: 'success',
      icon: FileDoneOutlined,
      path: SUCCESS.LIST,
    },
    {
      breadcrumb: false,
      title: 'Difficultés',
      key: 'difficulties',
      icon: FileExclamationOutlined,
      path: DIFFICULTY.LIST,
    },
    {
      breadcrumb: false,
      title: 'Solutions',
      key: 'solutions',
      icon: FileDoneOutlined,
      path: SOLUTION.LIST,
    },
    {
      breadcrumb: false,
      title: 'Recommandations',
      key: 'recommandations',
      icon: FileDoneOutlined,
      path: RECOMMANDATION.LIST,
    },
    // {
    //   breadcrumb: false,
    //   title: 'Lier les réussites',
    //   key: 'link-success',
    //   icon: FileDoneOutlined,
    //   path: LINK_SUCCESS.LIST,
    // },
    {
      breadcrumb: false,
      title: 'Lier les données',
      key: 'link-difficulty',
      icon: IssuesCloseOutlined,
      path: LINK_DIFFICULTY.LIST,
    },
    // {
    //   breadcrumb: false,
    //   title: 'Lier les recommandations',
    //   key: 'rlink-ecommandations',
    //   icon: IssuesCloseOutlined,
    //   path: LINK_RECOMMANDATION.LIST,
    // },
    {
      breadcrumb: false,
      title: 'Certificats',
      key: 'certificates',
      icon: AuditOutlined,
      path: CERTIFICATE.LIST,
    },
    {
      breadcrumb: false,
      title: 'Etapes évaluations',
      key: 'step',
      icon: FlagOutlined,
      path: STEP.LIST,
    },
    {
      breadcrumb: false,
      title: 'Evaluations',
      key: 'evaluations',
      icon: FlagOutlined,
      path: EVALUATION.LIST,
    }
  ]
}, {
  key: 'general',
  title: 'Administration',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      breadcrumb: false,
      title: 'Administrateurs',
      key: 'administrators',
      icon: TeamOutlined,
      path: USER.LIST,
    },
    {
      key: 'general-logout',
      title: 'Déconnexion',
      icon: LogoutOutlined,
      breadcrumb: false,
      action: 'logout'
    },
  ]
}];
