const PREFIX = 'users';

export const CREATE = `${PREFIX}`;
export const GET_ALL = `${PREFIX}`;
export const KYC = `${PREFIX}/kyc`;
export const LOGOUT = `${PREFIX}/logout`;
export const LOGIN_USER_ACCOUNT = `oauth/token`;
export const UPDATE = (reference: string) => `${PREFIX}/${reference}`;
export const DELETE = (reference: string) => `${PREFIX}/${reference}`;
export const GET_ONE = (reference: string) => `${PREFIX}/${reference}`;
export const PASSWORD = (reference: string) => `${PREFIX}/${reference}/password`;
