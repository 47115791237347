import * as Routes from "./routes";
import Utils from '../../utils/index';
import fetch from '../../auth/FetchInterceptor';
import { OAUTH } from "../../configs/AppConfig";

export default class UserService {

    static loginUserAccount(data: any) {
        let headers = Utils.getFullAuthorisationRequestConfig();
        data.client_id = OAUTH.clientId;
        data.grant_type = OAUTH.grantType;
        return fetch({
            url: Routes.LOGIN_USER_ACCOUNT,
            method: 'post',
            headers,
            data
        });
    }

    static create(data: any) {
        return fetch({
            data,
            method: 'post',
            url: Routes.CREATE,
        });
    }

    static update(reference: string, data: any) {
        return fetch({
            data,
            method: 'put',
            url: Routes.UPDATE(reference),
        });
    }

    static password(reference: string, data: any) {
        return fetch({
            data,
            method: 'put',
            url: Routes.PASSWORD(reference),
        });
    }

    static getOne(reference: string) {
        return fetch({
            url: Routes.GET_ONE(reference),
            method: 'get'
        });
    }

    static getAll() {
        return fetch({
            url: Routes.GET_ALL,
            method: 'get'
        });
    }

    static delete(reference: string) {
        return fetch({
            url: Routes.DELETE(reference),
            method: 'delete'
        });
    }

    static logout() {
        return fetch({
            url: Routes.LOGOUT,
            method: 'delete'
        });
    }

}