import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const HOME = `${APP_PREFIX_PATH}/home`;

export const LANDING = {
    ROOT: `/`,
    HOME: `/home`,
}

export const AUTH = {
    LOGIN: `${AUTH_PREFIX_PATH}/login`
}

export const ACTIVITY = {
    SELF: `${APP_PREFIX_PATH}/activities`,
    LIST: `${APP_PREFIX_PATH}/activities/list`,
}

export const DIFFICULTY = {
    SELF: `${APP_PREFIX_PATH}/difficulties`,
    LIST: `${APP_PREFIX_PATH}/difficulties/list`,
}

export const SOLUTION = {
    SELF: `${APP_PREFIX_PATH}/solutions`,
    LIST: `${APP_PREFIX_PATH}/solutions/list`,
}

export const MODULE = {
    SELF: `${APP_PREFIX_PATH}/modules`,
    LIST: `${APP_PREFIX_PATH}/modules/list`,
}

export const EXPERTISE = {
    SELF: `${APP_PREFIX_PATH}/expertises`,
    LIST: `${APP_PREFIX_PATH}/expertises/list`,
}

export const PARTICIPANT = {
    SELF: `${APP_PREFIX_PATH}/participants`,
    LIST: `${APP_PREFIX_PATH}/participants/list`,
}

export const FACILITATOR = {
    SELF: `${APP_PREFIX_PATH}/facilitators`,
    LIST: `${APP_PREFIX_PATH}/facilitators/list`,
}

export const CERTIFICATE = {
    SELF: `${APP_PREFIX_PATH}/certificates`,
    LIST: `${APP_PREFIX_PATH}/certificates/list`,
}

export const RECOMMANDATION = {
    SELF: `${APP_PREFIX_PATH}/recommandations`,
    LIST: `${APP_PREFIX_PATH}/recommandations/list`,
}

export const SESSION = {
    SELF: `${APP_PREFIX_PATH}/sessions`,
    LIST: `${APP_PREFIX_PATH}/sessions/list`,
}

export const REASON = {
    SELF: `${APP_PREFIX_PATH}/reasons`,
    LIST: `${APP_PREFIX_PATH}/reasons/list`,
}

export const PARTICIPATION = {
    SELF: `${APP_PREFIX_PATH}/participations`,
    LIST: `${APP_PREFIX_PATH}/participations/list`,
}

export const USER = {
    SELF: `${APP_PREFIX_PATH}/users`,
    LIST: `${APP_PREFIX_PATH}/users/list`,
}

export const EVALUATION = {
    SELF: `${APP_PREFIX_PATH}/evaluations`,
    LIST: `${APP_PREFIX_PATH}/evaluations/list`,
}

export const SKILL = {
    SELF: `${APP_PREFIX_PATH}/skills`,
    LIST: `${APP_PREFIX_PATH}/skills/list`,
}

export const STEP = {
    SELF: `${APP_PREFIX_PATH}/steps`,
    LIST: `${APP_PREFIX_PATH}/steps/list`,
}

export const SUCCESS = {
    SELF: `${APP_PREFIX_PATH}/successes`,
    LIST: `${APP_PREFIX_PATH}/successes/list`,
}

export const LINK_SUCCESS = {
    SELF: `${APP_PREFIX_PATH}/link-successes`,
    LIST: `${APP_PREFIX_PATH}/link-successes/list`,
}

export const LINK_RECOMMANDATION = {
    SELF: `${APP_PREFIX_PATH}/link-recommandations`,
    LIST: `${APP_PREFIX_PATH}/link-recommandations/list`,
}

export const LINK_DIFFICULTY = {
    SELF: `${APP_PREFIX_PATH}/link-datas`,
    LIST: `${APP_PREFIX_PATH}/link-datas/list`,
}
