import React from 'react';

export default function Footer() {
	return (
		<footer className="footer">
			<span>Copyrights  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">WEPIC</span> Tous droits reservés</span>
		</footer>
	)
}

